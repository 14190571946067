import React from 'react'
import "../styles/Footer.css"
const Footer = () => {
  return (
    <div className='footer-container'>
      <p>Copywrite</p> 
      <p>&copy;</p>
      <p>2024; Designed By</p>
      <p>PRIYANSH BARYA</p>
    </div>
  )
}

export default Footer